"use client";
import { useTranslation } from "@/src/i18n/client";
import styles from "../error.module.scss";
import classNames from "classnames";
import ErrorMain from "../components/ErrorMain";

export default function CustomError404() {
    const { t } = useTranslation("common");
    const title = t(`common:ErrorPage.404.p1`);
    const desc_1 = t(`common:ErrorPage.404.p2`);
    const desc_2 = t(`common:ErrorPage.404.p3`);
    const desc_3 = t(`common:ErrorPage.404.p4`);

    return (
        <ErrorMain type="404">
            <div>
                <p
                    className={classNames(
                        styles["error-statusCode"],
                        styles["text-center"],
                    )}
                >
                    404
                </p>
            </div>
            <div className={styles["tip"]}>{title}</div>
            <div className={styles["b-text"]}>
                <div>{desc_1}</div>
                <div>
                    {desc_2}
                    <a href="/" className={styles["href_a"]}>
                        {desc_3}
                    </a>
                </div>
            </div>

            <p className={styles["more-info"]}>
                datetime: {new Date().toLocaleString()} {Date.now()}
            </p>
        </ErrorMain>
    );
}
